@font-face {
    font-family: 'Alumni-Italic';
    src: url('../css/fonts/AlumniSansPinstripe-Italic.ttf');
}
@font-face {
    font-family: 'Alumni-Regular';
    src: url('../css/fonts/AlumniSansPinstripe-Regular.ttf');
}
@font-face {
    font-family: 'Amatic-Bold';
    src: url('../css/fonts/AmaticSC-Bold.ttf');
}
@font-face {
    font-family: 'Amatic-Regular';
    src: url('../css/fonts/AmaticSC-Regular.ttf');
}
@font-face {
    font-family: 'Honk-Regular';
    src: url('../css/fonts/Honk-Regular-VariableFont_MORF\,SHLN.ttf');
}
@font-face {
    font-family: 'Protest-Regular';
    src: url('../css/fonts/ProtestRevolution-Regular.ttf');
}
@font-face {
    font-family: 'RubicDoodle-Regular';
    src: url('../css/fonts/RubikDoodleShadow-Regular.ttf');
}

@font-face {
    font-family: 'RubicGlitch-Regular';
    src: url('../css/fonts/RubikGlitch-Regular.ttf');
}

@font-face {
    font-family: 'LatoLight';
    src: url('../css/fonts/Lato-Light.ttf');
}