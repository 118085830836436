html {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }
  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }

  /* .container {
    width: auto;
    max-width: 680px;
    padding: 0 15px;
  } */
  

  .footer {
    height: auto;
  }

.navbar-light{
  background-color: #e3f2fd;
}

  .logo, .logo:hover{
    font-family: 'Amatic-Regular';
    color: black;
    font-size: 35px;
  }

  h1, h2, h3, h4, h5, h6{
    font-family: 'RubicDoodle-Regular';
    /* font-family: 'Protest-Regular'; */
  }

  p{
    font-family: 'LatoLight';
    font-size: 24px;
  }
  .container {
    max-width: 960px;
  }
  
  .pricing-header {
    max-width: 700px;
  }
  
  .card-deck .card {
    min-width: 220px;
  }
  
  .border-top { border-top: 1px solid #e5e5e5; }
  .border-bottom { border-bottom: 1px solid #e5e5e5; }
  
  .box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

  #scene{
    background-color: aqua;
    width:  100%;
    height: 100%;
  }

  .infomail, .infomail:hover{
    text-decoration: none;
    font-family: 'LatoLight';
    color:black;
    margin-bottom:7px;
  }

/* .content{
  min-height: calc(100vh - 200px);
} */

/* body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  flex: 1;
}


  .footer{
    height:100px;
  } */